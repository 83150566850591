import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/useAuthStore'

export default () => {
  const auth = useAuth()
  const signIn = () => {}
  const signOut = () => {}

  const authStore = useAuthStore()
  const { user, token, organization } = storeToRefs(authStore)

  const setUser = (value: any) => {}

  return {
    authorized: computed(() => !!user.value.id),
    user,
    organization,
    token,
    setUser,
    signIn: auth.signIn,
    signOut: auth.signOut,
  }
}
